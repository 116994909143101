/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import Sockette from './utils/sockettewrapper';

import awsApi from '../../../redux/awsHelper/data/api';
import s3Bucket from '../../../redux/awsHelper';

import { IntakeAction } from '../containers/utils';
import FileHandlerUtils from '../utils/FileHandlerUtils';

import {
	getFileFormat,
	getFile,
	getFileName,
	replaceFile,
	cleanpUpMetadata,
	convertToBool
} from './utils';

import {
	selectSliceUploader,
	selectFile as selectorSelectFile,
	selectDefaultChecked
} from './selectors';
import { goodEnoughUUID } from '../../../../scanline/utils/uuid';

const NS_MAPS = 'uploader_';

const ASSUMED_AWS_TIMEOUT = 20000;

const confirmEdits = () => (dispatch, getState) => {
	const {
		uploader: { isEditing }
	} = getState();
	if (isEditing) {
		const confirmationMessage =
			'It looks like you have been editing. ' +
			'If you leave before saving, your changes will be lost.' +
			'Please confirm you want to leave.';

		// eslint-disable-next-line no-alert
		const isDone = window.confirm(confirmationMessage);
		return isDone;
	}
	return true;
};

export const SET_IS_EDITING = `${NS_MAPS}SET_IS_EDITING`;
export const setIsEditing = isEditing => {
	return {
		type: SET_IS_EDITING,
		payload: isEditing
	};
};

export const SET_ACTION = `${NS_MAPS}SET_ACTION`;
export const setAction = action => dispatch => {
	const isSafe = dispatch(confirmEdits());
	if (!isSafe) {
		return;
	}

	dispatch(setIsEditing(false));
	dispatch({
		type: SET_ACTION,
		payload: action
	});
};

export const SET_SOCKETTE = `${NS_MAPS}SET_SOCKETTE`;
export const setSockette = sockette => {
	return {
		type: SET_SOCKETTE,
		payload: sockette
	};
};
export const SET_WEB_SOCKET = `${NS_MAPS}SET_WEB_SOCKET`;
const setWebSocket = websocket => {
	return {
		type: SET_WEB_SOCKET,
		payload: websocket
	};
};

export const ADD_WEB_SOCKET_NEXT = `${NS_MAPS}ADD_WEB_SOCKET_NEXT`;
export const addOneToWSNext = () => {
	return {
		type: ADD_WEB_SOCKET_NEXT
	};
};

export const ADD_WEB_SOCKET_RECEIVED = `${NS_MAPS}ADD_WEB_SOCKET_RECEIVED`;
export const addOneToWSReceived = () => {
	return {
		type: ADD_WEB_SOCKET_RECEIVED
	};
};

export const SET_WEB_SOCKET_CONNECTION_ID = `${NS_MAPS}SET_WEB_SOCKET_CONNECTION_ID`;
export const setConnectionID = connectionId => {
	return {
		type: SET_WEB_SOCKET_CONNECTION_ID,
		payload: connectionId
	};
};

export const SET_S3_LOCATION = `${NS_MAPS}SET_S3_LOCATION`;
const setS3Location = (folder, bucket) => {
	return {
		type: SET_S3_LOCATION,
		payload: { folder, bucket }
	};
};

export const SET_S3 = `${NS_MAPS}SET_S3`;
const setS3 = s3 => {
	return {
		type: SET_S3,
		payload: s3
	};
};

export const SET_FILES = `${NS_MAPS}SET_FILES`;
const setFiles = files => {
	return {
		type: SET_FILES,
		payload: files
	};
};

export const ADD_FILES_COUNT = `${NS_MAPS}ADD_FILES_COUNT`;
const addFilesCount = () => {
	return {
		type: ADD_FILES_COUNT
	};
};
export const SUBTRACT_FILES_COUNT = `${NS_MAPS}SUBTRACT_FILES_COUNT`;
const subtractFilesCount = () => {
	return {
		type: SUBTRACT_FILES_COUNT
	};
};

export const SET_SELECTED_FILE = `${NS_MAPS}SET_SELECTED_FILE`;
const setSelectedFile = file => {
	return {
		type: SET_SELECTED_FILE,
		payload: file
	};
};

export const SET_EDITING_RESULTS = `${NS_MAPS}SET_EDITING_RESULTS`;
export const setEditingResults = payload => {
	return {
		type: SET_EDITING_RESULTS,
		payload
	};
};

export const SET_DEFAULT_CHECKED = `${NS_MAPS}SET_DEFAULT_CHECKED`;
export const setDefaultChecked = defaultChecked => {
	return {
		type: SET_DEFAULT_CHECKED,
		payload: defaultChecked
	};
};

export const SET_ON_CLOSE = `${NS_MAPS}SET_ON_CLOSE`;
const setOnClose = onClose => {
	return {
		type: SET_ON_CLOSE,
		payload: onClose
	};
};

export const SET_VERIFY_UPLOAD = `${NS_MAPS}SET_VERIFY_UPLOAD`;
const setVerifyUpload = verifyUpload => {
	return {
		type: SET_VERIFY_UPLOAD,
		payload: verifyUpload
	};
};

export const SET_SUBMIT_FILE_EXTERNAL = `${NS_MAPS}SET_SUBMIT_FILE_EXTERNAL`;
const setSubmitFileExternal = submitFileExternal => {
	return {
		type: SET_SUBMIT_FILE_EXTERNAL,
		payload: submitFileExternal
	};
};

export const SET_IS_INIT = `${NS_MAPS}SET_IS_INIT`;
export const setIsInit = isInit => {
	return {
		type: SET_IS_INIT,
		payload: isInit
	};
};

export const SET_TEMPLATE = `${NS_MAPS}SET_TEMPLATE`;
export const setTemplate = template => {
	return {
		type: SET_TEMPLATE,
		payload: template
	};
};

const checkS3 = () => (dispatch, getState) => {
	const {
		uploader: { s3, folder, bucket }
	} = getState();
	const isValidToken = s3.validToken();
	if (!isValidToken) {
		// eslint-disable-next-line no-use-before-define
		return dispatch(initS3(folder, bucket));
	}
	return Promise.resolve();
};

export const selectFile = file => (dispatch, getState) => {
	const isSafe = dispatch(confirmEdits());
	if (!isSafe) {
		return;
	}
	dispatch(setIsEditing(false));
	dispatch(setEditingResults());
	const {
		uploader: { s3, bucket, folder }
	} = getState();

	const params = {
		Bucket: bucket,
		Key: `${folder}/${file.name}`
	};
	dispatch(setSelectedFile({ ...file, isPending: true }));
	dispatch(checkS3()).then(() => {
		s3.getObject(params)
			.promise()
			.then(({ Body }) => {
				const data = Body.toString('utf-8');
				dispatch(setSelectedFile({ ...file, data, isPending: false }));
				dispatch(setAction(IntakeAction.EDIT_FILE));
			});
	});
};

export const addFile = (fileFormat, file) => (dispatch, getState) => {
	const {
		uploader: { files }
	} = getState();
	if (files[fileFormat] === undefined) {
		files[fileFormat] = [];
	}
	const newFiles = { ...files };
	const stateUploader = selectSliceUploader(getState());
	const matchFile = selectorSelectFile(stateUploader, fileFormat, file.name);
	if (matchFile !== undefined) {
		const updateFiles = replaceFile(file, files);
		dispatch(setFiles(updateFiles));
		return;
	}

	newFiles[fileFormat] = [...newFiles[fileFormat], file];
	dispatch(addFilesCount());
	dispatch(setFiles(newFiles));
};

export const SET_IS_SHOWING = `${NS_MAPS}SET_IS_SHOWING`;
const setIsShowing = isShowing => {
	return {
		type: SET_IS_SHOWING,
		payload: isShowing
	};
};

export const showModal = () => dispatch => {
	dispatch(setIsShowing(true));
};

export const hideModal = () => dispatch => {
	const isSafe = dispatch(confirmEdits());
	if (!isSafe) {
		return isSafe;
	}
	dispatch(setIsEditing(false));
	dispatch(setIsShowing(false));
	return true;
};

const removeFile = (fileFormat, file) => (dispatch, getState) => {
	const {
		uploader: { files }
	} = getState();
	if (files[fileFormat] === undefined) {
		return;
	}
	const newFiles = { ...files };
	newFiles[fileFormat] = [...newFiles[fileFormat]];
	const [matchFile] = newFiles[fileFormat].filter(f => f.name === file.name);
	if (matchFile !== undefined) {
		newFiles[fileFormat].splice(newFiles[fileFormat].indexOf(matchFile), 1);
	}

	dispatch(subtractFilesCount());
	dispatch(setFiles(newFiles));
};

export const updateIsProcessing = (file, isProcessing) => (
	dispatch,
	getState
) => {
	const {
		uploader: { files }
	} = getState();
	const { name } = file;
	const matchFile = getFile(name, files);
	const newFile = { ...matchFile, isProcessing };

	const newFiles = replaceFile(newFile, files);

	dispatch(setFiles(newFiles));
	return newFile;
};

export const updateUploadError = file => (dispatch, getState) => {
	const {
		uploader: { files }
	} = getState();
	const { name } = file;
	const matchFile = getFile(name, files);
	const newFile = { ...matchFile, uploadError: true };

	const newFiles = replaceFile(newFile, files);

	dispatch(setFiles(newFiles));
	return newFile;
};

const wsUpdateFile = ({ key, errors, infoSummary, warnings }) => (
	dispatch,
	getState
) => {
	const {
		uploader: { files, selectedFile, folder }
	} = getState();

	const fileName = getFileName(key);
	const matchFile = getFile(fileName, files);
	const fileFormat = getFileFormat(fileName);

	const isTSData = folder.split('/')[4] === 'TS Data';

	const updateAttrs = {
		errors: convertToBool(errors),
		infosummary: infoSummary,
		warnings: convertToBool(warnings),
		disabled: !isTSData && convertToBool(errors)
	};

	const newFile = {
		...matchFile,
		...updateAttrs
	};
	if (newFile.disabled) {
		const defaultChecked = selectDefaultChecked(
			selectSliceUploader(getState())
		);
		newFile.isChecked = defaultChecked;
	}

	newFile.status = FileHandlerUtils.getFileResultStatus(newFile, {
		surveyType: fileFormat
	});

	newFile.isProcessing = false;
	const newFiles = replaceFile(newFile, files);

	dispatch(setFiles(newFiles));
	// dispatch(updateIsProcessing(newFile, false));
	if (selectedFile && selectedFile.name === fileName) {
		dispatch(setSelectedFile({ ...selectedFile, ...updateAttrs }));
	}
};

export const updateSurveyType = (file, surveyType) => (dispatch, getState) => {
	const {
		uploader: { files }
	} = getState();
	const { name } = file;
	const matchFile = getFile(name, files);
	const newFile = { ...matchFile, surveyType };

	const newFiles = replaceFile(newFile, files);

	dispatch(setFiles(newFiles));
};

export const checkFile = (evt, file) => (dispatch, getState) => {
	const isChecked = evt.target.checked;
	evt.stopPropagation();
	const {
		uploader: { files }
	} = getState();

	const { name } = file;
	const matchFile = getFile(name, files);
	const newFile = { ...matchFile, isChecked };

	const newFiles = replaceFile(newFile, files);
	dispatch(setFiles(newFiles));
	const fileFormat = getFileFormat(name);

	if (FileHandlerUtils.isFileEditable(fileFormat)) {
		dispatch(selectFile(newFile));
	}
};

const saveS3 = (body, fileName) => (dispatch, getState) => {
	const {
		uploader: { bucket, folder, s3, connectionId }
	} = getState();
	const params = {
		Body: body,
		Bucket: bucket,
		Key: `${folder}/${fileName}`,
		Metadata: {
			connectionid: connectionId,
			issubmit: 'false'
		}
	};
	toast.success(`Updating ${fileName}`, {
		position: toast.POSITION.TOP_RIGHT,
		autoClose: 4000
	});
	return dispatch(checkS3()).then(() => {
		return s3
			.putObject(params)
			.promise()
			.then(() => {
				return {};
			});
	});
};

export const saveFile = body => (dispatch, getState) => {
	const {
		uploader: { bucket, folder, s3, selectedFile, connectionId }
	} = getState();
	const params = {
		Body: body,
		Bucket: bucket,
		Key: `${folder}/${selectedFile.name}`,
		Metadata: {
			connectionid: connectionId,
			issubmit: 'false'
		}
	};
	toast.success(`Updating ${selectedFile.name}`, {
		position: toast.POSITION.TOP_RIGHT,
		autoClose: 4000
	});

	dispatch(updateIsProcessing(selectedFile, true));
	return dispatch(checkS3()).then(() => {
		return s3
			.putObject(params)
			.promise()
			.then(() => {
				return dispatch(setSelectedFile({ ...selectedFile, data: body }));
			});
	});
};

export const submitFile = (file, data) => (dispatch, getState) => {
	toast.success(`Submitting ${file.name}`, {
		position: toast.POSITION.TOP_RIGHT,
		autoClose: 4000
	});
	dispatch(updateIsProcessing(file, true));
	const {
		uploader: {
			bucket,
			folder,
			s3,
			submitFileExternal,
			selectedFile,
			connectionId,
			webSocket
		}
	} = getState();
	const { name } = file;

	// talk to Will about onFileSubmitPromise from AIP-Frontend/react/scanline/src/scanline/containers/Map.jsx
	return submitFileExternal({
		name,
		bucket,
		folder,
		surveyType: file.surveyType,
		connectionId,
		webSocket
	}).then(res => {
		const { results } = res;
		if (results === undefined || results === 'success') {
			const params = {
				Bucket: bucket,
				Key: `${folder}/${file.name}`,
				Metadata: {
					issubmit: 'true',
					connectionid: ''
				}
			};
			if (data !== undefined && file.data !== data) {
				params.Body = data;
			}
			return dispatch(checkS3())
				.then(() => {
					return s3
						.putObject(params)
						.promise()
						.then(() => {
							const fileFormat = getFileFormat(file.name);
							dispatch(removeFile(fileFormat, file));
							if (selectedFile && selectedFile.name === file.name) {
								dispatch(setAction(IntakeAction.UPLOAD));
								dispatch(setSelectedFile());
							}
							return file;
						})
						.then(() => {
							const {
								uploader: { filesCount, onClose }
							} = getState();
							if (filesCount === 0) {
								dispatch(hideModal());
								onClose();
							}
						});
				})
				.catch(() => {
					dispatch(updateIsProcessing(file, true));
				});
		}
		dispatch(updateIsProcessing(file, false));
		dispatch(updateUploadError(file));
		toast.success(`There was an issue submitting ${file.name}`, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: false,
			hideProgressBar: true
		});
		return {};
	});
};

export const submitFiles = fileFormat => (dispatch, getState) => {
	const {
		uploader: { files }
	} = getState();
	if (files[fileFormat] === undefined) {
		return;
	}
	Promise.all(
		files[fileFormat]
			.filter(({ isChecked }) => isChecked)
			.map(file => dispatch(submitFile(file)))
	);
};

export const deleteFile = file => (dispatch, getState) => {
	const isSafe = dispatch(confirmEdits());
	if (!isSafe) {
		return Promise.resolve();
	}
	dispatch(setIsEditing(false));

	toast.success(`Removing ${file.name}`, {
		position: toast.POSITION.TOP_RIGHT,
		autoClose: 4000
	});
	dispatch(updateIsProcessing(file, true));
	const {
		uploader: { s3, bucket, folder }
	} = getState();

	const params = {
		Bucket: bucket,
		Key: `${folder}/${file.name}`,
		Metadata: {
			isHidden: 'true'
		}
	};
	return dispatch(checkS3()).then(() => {
		s3.putObject(params)
			.promise()
			.then(() => {
				const fileFormat = getFileFormat(file.name);
				dispatch(removeFile(fileFormat, file));
				dispatch(setAction(IntakeAction.UPLOAD));
			});
	});
};

const closeSockette = () => (dispatch, getState) => {
	const {
		uploader: { sockette }
	} = getState();
	if (sockette !== undefined) {
		try {
			sockette.close();
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	}
};

const onSocketteOpen = e => (dispatch, getState) => {
	dispatch(setWebSocket(e.target));
	const {
		uploader: { sockette }
	} = getState();
	sockette.send('{"action":"getid"}');
	dispatch(addOneToWSNext());
};

const onSocketteReceive = e => dispatch => {
	dispatch(addOneToWSReceived());
	if (e.data.search('key') === -1) {
		dispatch(setConnectionID(e.data));
		return;
	}
	const resultObject = JSON.parse(e.data);
	const { action } = resultObject;
	if (action === undefined) {
		dispatch(wsUpdateFile(resultObject));
	}
};

const initSockette = url => dispatch => {
	dispatch(closeSockette());
	const ws = new Sockette(url, {
		onopen: e => dispatch(onSocketteOpen(e)),
		onmessage: e => dispatch(onSocketteReceive(e))
	});
	dispatch(setSockette(ws));
};

export const cleanUp = () => dispatch => {
	dispatch(closeSockette());
	dispatch(setSockette());
};

const processS3Content = (content, { requestToken } = {}) => (
	dispatch,
	getState
) => {
	const {
		uploader: { s3, bucket, defaultChecked, folder, template }
	} = getState();

	const params = {
		Bucket: bucket,
		Key: content.Key
	};
	const isTSData = folder.split('/')[4] === 'TS Data';
	return dispatch(checkS3()).then(() => {
		return s3
			.headObject(params)
			.promise()
			.then(res => {
				const {
					Metadata: {
						ishidden = 'false',
						issubmit = 'false',
						infosummary = '',
						warnings = false,
						errors = false
					},
					ETag,
					ContentLength,
					LastModified
				} = cleanpUpMetadata(res);
				if (ishidden === 'true') {
					return {};
				}
				if (issubmit === 'true') {
					return {};
				}

				// const errors = JSON.parse(strErrors);
				// const warnings = JSON.parse(strWarnings);
				const fileName = getFileName(content.Key);
				const fileFormat = getFileFormat(fileName);
				const stateUploader = selectSliceUploader(getState());
				const currentFileObj =
					selectorSelectFile(stateUploader, fileFormat, fileName) || {};
				const file = {
					...currentFileObj,
					name: getFileName(content.Key),
					infosummary,
					warnings,
					errors,
					id: ETag,
					date: LastModified,
					size: ContentLength,
					isChecked: defaultChecked,
					isProcessing: content.uploading,
					disabled: !isTSData && convertToBool(errors)
				};
				if (requestToken) {
					file.previousRequestToken = requestToken;
				}
				if (file.disabled && template !== 'scanline') {
					file.isChecked = false;
				}
				file.status = FileHandlerUtils.getFileResultStatus(file, {
					surveyType: fileFormat
				});
				dispatch(addFile(fileFormat, file));
				return file;
			});
	});
};

const _getFile = (getState, fileName) => {
	const fileFormat = getFileFormat(fileName);
	const stateUploader = selectSliceUploader(getState());
	const file = selectorSelectFile(stateUploader, fileFormat, fileName);
	return file;
};

export const uploadedFile = fileName => (dispatch, getState) => {
	const {
		uploader: { folder }
	} = getState();
	const fileFormat = getFileFormat(fileName);

	const requestToken = goodEnoughUUID();
	dispatch(
		processS3Content(
			{
				Key: `${folder}/${fileName}`,
				uploading: FileHandlerUtils.isFileEditable(fileFormat)
			},
			{ requestToken }
		)
	);
	setTimeout(() => {
		const file = _getFile(getState, fileName);
		if (!file || (file && file.previousRequestToken !== requestToken)) {
			dispatch(processS3Content({ Key: `${folder}/${fileName}` }));
		}
	}, ASSUMED_AWS_TIMEOUT + 1000);
};

export const checkUploadFile = uploadFile => (dispatch, getState) => {
	const {
		uploader: { files, verifyUpload }
	} = getState();
	const file = getFile(uploadFile.name, files);
	if (file !== undefined) {
		// eslint-disable-next-line no-alert
		const newFileName = window.prompt(
			`${file.name} already exists in Uploader. Please choose a different file name or it will overwrite the current file.`,
			file.name
		);
		if (newFileName === null) {
			return { isReady: false };
		}
		// ToDo: should check name again
		return { isReady: true, fileName: newFileName };
	}
	const isApproved = verifyUpload(uploadFile);
	if (!isApproved) {
		// eslint-disable-next-line no-alert
		const newFileName = window.prompt(
			// ToDo: need to fix hard coded appName
			`${uploadFile.name} already Submitted to CISVIEW. Please choose a different file name.`,
			uploadFile.name
		);
		if (newFileName === null) {
			return { isReady: false };
		}
		// ToDo: should check name again
		return { isReady: true, fileName: newFileName };
	}
	return { isReady: true, fileName: uploadFile.name };
};

// first call comes from AIP-Frontend/react/scanline/src/commons/redux/awsHelper/data/sagas.js
export const getS3Files = () => (dispatch, getState) => {
	const {
		uploader: { s3, bucket, folder }
	} = getState();
	const params = {
		Bucket: bucket,
		Prefix: `${folder}/`
	};
	// only the first 1000 this maybe an issue
	dispatch(checkS3()).then(() => {
		s3.listObjects(params)
			.promise()
			.then(({ Contents }) => {
				const processFiles = Contents.map(content => {
					return dispatch(processS3Content(content));
				});
				Promise.all(processFiles).then(() => {
					dispatch(setIsInit(false));
					// finished processing files
				});
			});
	});
	// replace initAWS from index.js
	// debugger;
};

function getEndRow(idx, annotations, editor) {
	const nextAnnotation = annotations[idx + 1];
	if (nextAnnotation) {
		const { row: nextRow } = nextAnnotation;
		return nextRow;
	}
	return editor.getSession().getLength();
}

function getNewFileName(file, existingName, date, idx) {
	if (file) {
		return `${file}.csv`;
	}
	return `${date.replace(/\//g, '_')}_${idx}_${existingName}`;
}

function csvSection(annotation, headerLine, editor, annotations, existingName) {
	const { Range } = window.ace.acequire('ace/range');
	const { row, file, date } = annotation;

	const idx = annotations.indexOf(annotation);
	const nextRow = getEndRow(idx, annotations, editor);
	const body = `${headerLine}${editor
		.getSession()
		.getTextRange(new Range(row, 0, nextRow, 0))}`;
	return { body, fileName: getNewFileName(file, existingName, date, idx) };
}

export const splitCSV = editor => (dispatch, getState) => {
	// eslint-disable-next-line no-alert
	/* const resPrompt = window.prompt(
		"Type in 'separate' to verify you want to do split up the CSV File.'"
	);
	if (resPrompt !== 'separate') {
		return undefined;
	}  */
	const {
		uploader: {
			selectedFile,
			selectedFile: { name: existingName }
		}
	} = getState();
	const { Range } = window.ace.acequire('ace/range');
	const annotations = editor
		.getSession()
		.getAnnotations()
		.filter(({ type }) => type === 'segment');
	const headerLine = editor.getSession().getTextRange(new Range(0, 0, 1, 0));
	const files = annotations.reduce((acc, annotation, idx, arr) => {
		if (idx < arr.length - 1) {
			acc.push(
				csvSection(annotation, headerLine, editor, annotations, existingName)
			);
		}
		return acc;
	}, []);
	files.push(
		csvSection(
			annotations[annotations.length - 1],
			headerLine,
			editor,
			annotations,
			existingName
		)
	);

	return Promise.all(
		files.map(file => {
			const { isReady, fileName = file.fileName, ...rest } = dispatch(
				checkUploadFile({ name: file.fileName })
			);
			const { body } = file;
			if (isReady) {
				const fileFormat = getFileFormat(fileName);
				dispatch(addFile(fileFormat, { name: fileName, isProcessing: true }));
				return dispatch(saveS3(body, fileName)).then(() => {
					return { ...file, fileName, isReady, body, ...rest };
				});
			}
			return { ...file, fileName, isReady, body, ...rest };
		})
	).then(() => {
		if (files.length > 0) {
			dispatch(deleteFile(selectedFile));
		}
	});
};

const initS3 = (folder, bucket) => dispatch => {
	dispatch(setS3Location(folder, bucket));
	return awsApi.getAccess().then(res => {
		const s3 = s3Bucket(res.Credentials);
		dispatch(setS3(s3));

		dispatch(getS3Files());
		return Promise.resolve({});
	});
	// dispatch(getS3Files(folder, bucket));
};

export const init = params => dispatch => {
	// console.log(params);
	// debugger;
	const {
		template,
		websocketUrl,
		folder,
		bucket,
		accessUrl,
		verifyUpload = () => {
			throw new Error(
				'Uploader must implement prop verifyUpload and return a bool'
			);
		},
		submitFile: submitFileExternal = () => {
			throw new Error('Uploader must implement prop submitFile');
		},
		onClose = () => {}
	} = params;
	dispatch(setTemplate(template));
	dispatch(initSockette(websocketUrl));
	dispatch(initS3(folder, bucket, accessUrl));
	dispatch(setVerifyUpload(verifyUpload));
	dispatch(setSubmitFileExternal(submitFileExternal));
	dispatch(setOnClose(onClose));
};
