// aip.corrprocanada.com
const STAGE = 'prod';
const URL = 'https://api.aip.corrprocanada.com';
const WS = 'wss://ws.aip.corrprocanada.com';

const TAB_PREFIX = '';
const TAB_SUFFIX = 'AIP';

const aipurls = {
	admin: `${URL}/admin`,
	account: `${URL}/account`,
	assetview: `${URL}/assetview`,
	assetviewWS: `${WS}/assetview`,
	assetviewCorrelator: `${URL}/assetview-correlator`,
	customerusers: `${URL}/customer-users`,
	cisview: `${URL}/cisview`,
	cisviewextended: `${URL}/cisview-extended`,
	cisviewreadonly: `${URL}/cisview-readonly`,
	cisviewedits: `${URL}/cisview-edits`,
	dashboard: `${URL}/dashboard`,
	fieldline: `${URL}/fieldline`,
	fieldlinebinary: `${URL}/fieldline-binary`,
	chargeline: `${URL}/chargeline`,
	chargelinebinary: `${URL}/chargeline-binary`,
	maps: `${URL}/maps`,
	scanline: `${URL}/scanline`,
	scanlineWorkflow: `${URL}/scanline-workflow`,
	scanlineReportV2: `${URL}/scanline-report-v2`,
	scanlineReportV2WS: `${WS}/scanline-report-v2`,
	scanlineTags: `${URL}/scanline-tags`,
	templateViewer:
		`${URL}/internal-template-viewer-2`,
	templates: `${URL}/templates`,
	scanlineReport: `${URL}/scanlineReport`,
	liveline: `${URL}/liveline`,
	settings: `${URL}/settings`,
	uploaderbucket: 'corrprocanada-aip-prod',
	uploderbucketbasepath: `${URL}/uploader`,
	uploaderwebsocket: `${WS}/uploader`,
	uploaderbucketaccessurl: `${URL}/uploader/access`,
	uploaderfilesubmiturl:
		`${URL}/cisview-extended/postUploaderSubmit`,
	parseFile: `${URL}/parse-file`,
	parseFileWS: `${WS}/parse-file`,
	mlServerApi: 'https://ml.aip.corrprocanada.com/',
	mlServerLambdaApi: `${URL}/ml/`,
	downloadReport: `${URL}/dataprocessor-report`,
	notifications: `${URL}/aip-notifications`,
	viewAs: `${URL}/view-as`,
	dataIntegrity: `${URL}/dataIntegrity`,

	// COGNITO
	poolid: 'cognito-idp.us-west-2.amazonaws.com/us-west-2_8wbzgfn2H',
	identifypoolid: 'us-west-2:7bb4fa34-ff99-450f-ad1e-80ef94e1eea4',
	clientid: '4v5s182eqtcs252cg05ug6emu8',

	// BUCKETS
	bucket: `corrprocanada-aip-${STAGE}-nonversioned`,
	versionedBucket: `corrprocanada-aip-${STAGE}`,
};

export default aipurls;

export { URL, TAB_PREFIX, TAB_SUFFIX };
